import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { memo, ReactNode, useCallback, useEffect } from 'react';
import { LayoutWrapper } from './styled';
import useGeneralFunction from '@containers/Home/hook/useGeneralFunction';
import { selectDetailProject } from '@containers/Home/selectors';
import { useSelector } from 'react-redux';

const DialogGuide = dynamic(() => import('@components/DialogGuide'));
const DialogNavigate = dynamic(() => import('@components/DialogNavigate'), {
  ssr: false,
});

interface Props {
  children: ReactNode;
}

const Layout = memo(({ children }: Props) => {
  const router = useRouter();
  const detailProject = useSelector(selectDetailProject);

  const {
    isOpenDialogNavigate,
    isOpenDialogGuide,
    onOpenDialogNavigate,
    onCloseDialogNavigate,
    onCloseDialogGuide,
  } = useGeneralFunction();

  const onRouterStart = useCallback(() => {}, []);

  const onRouterEnd = useCallback(() => {
    console.log('route change end');
  }, []);

  useEffect(() => {
    if (router) {
      router.events.on('beforeHistoryChange', onRouterStart);
      router.events.on('routeChangeComplete', onRouterEnd);
    }
    return () => {
      if (router) {
        router.events.off('beforeHistoryChange', onRouterStart);
        router.events.off('routeChangeComplete', onRouterEnd);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutWrapper>
      {/* <div className="--open" onClick={onOpenDialogNavigate}>
        <i aria-hidden className="fas fa-bars" aria-hidden />
      </div> */}
      {children}
      <DialogNavigate
        visible={isOpenDialogNavigate}
        isCloseable={
          detailProject?.slug !==
          (process.env.TOUR_ID_0 || process.env.NEXT_PUBLIC_TOUR_ID_0)
        }
        onCancel={onCloseDialogNavigate}
        onOpen={onOpenDialogNavigate}
      />
      <DialogGuide visible={isOpenDialogGuide} onCancel={onCloseDialogGuide} />
    </LayoutWrapper>
  );
});

export default Layout;
